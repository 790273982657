<template>
	<div>
		<div class="d-flex">
			<span class="display-1 mb-3">
				{{ company.name }}
			</span>
			<CompanyInfoUpdateForm :loading-parent="loadingParent || loading" />
		</div>
		<div class="d-flex justify-left mb-3">
			<span class="body-2 mr-3" style="word-break: break-all">
				<v-icon>mdi-earth</v-icon> <a :href="company.info.website" target="_blank">{{ company.info.website }}</a>
			</span>
			<span class="body-2 mx-3"> <v-icon>mdi-account-group</v-icon> {{ $t('companies.numEmployees', { num: company.info.employees.name }) }} </span>
		</div>
		<div class="d-flex justify-left mb-3">
			<template v-for="(socialAccount, socialNetwork) in company.social">
				<div class="px-1 text-center" :key="socialNetwork" v-if="socialAccount">
					<v-btn icon :href="`https://${socialNetwork}.com/${socialAccount}`" target="_blank">
						<v-icon>mdi-{{ socialNetwork }}</v-icon>
					</v-btn>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'CompanyInfo',
	props: {
		loadingParent: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			loading: false
		}
	},
	components: {
		CompanyInfoUpdateForm: () => import('@/components/companies/CompanyInfoUpdateForm.vue')
	},
	computed: {
		...mapGetters({
			company: 'companies/company'
		})
	}
}
</script>
